.App {
  text-align: center;
}

.floating { 
  animation-name: floating;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, -15px); }
  100%   { transform: translate(0, -0px); }   
}